import React, { useEffect, useState, useRef } from 'react'
import { Heading2, Heading3 } from '../../styles/vars/textStyles.style'

// Components
import Container from '../Container/index'
import Grid from '../Grid/index'
import GridItem from '../GridItem/index'
import Portal from '../Portal'
import RichText from '../RichText'

import {
  TextModal,
  TextModalInner,
  CloseButton,
  TextWrap,
  Outer,
} from './index.style'

const Modal = ({ modalOpen, setModalOpen, popupCopy, subheading, heading }) => {
  const [inner, setInner] = useState(false)

  const timerRef = useRef(null)

  useEffect(() => {
    if (modalOpen) {
      timerRef.current = setTimeout(() => {
        setInner(true)
      }, 500)
    } else {
      setInner(false)
    }

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
    }
  }, [modalOpen])

  return (
    <div>
      <Portal>
        <Outer modalOpen={modalOpen}>
          <TextModal
            modalOpen={inner}
            // onClick={() => setModalOpen(!modalOpen)}
            tabIndex={modalOpen ? 0 : -1}
            aria-live="polite"
            role="dialog"
            aria-label="Serving Human Well-being"
          >
            {/* <FocusTrap active={modalOpen}> */}
            <TextModalInner modalOpen={inner}>
              {modalOpen ? (
                <>
                  <Container>
                    <CloseButton
                      onClick={() => setModalOpen(!modalOpen)}
                      aria-label={`Close Modal`}
                      tabIndex={modalOpen ? 0 : -1}
                    >
                      Close
                    </CloseButton>
                    <Grid>
                      {subheading && (
                        <GridItem mobile={16} tabletL={3} tabletLStart={3}>
                          <Heading3 as={`h3`}>{subheading}</Heading3>
                        </GridItem>
                      )}

                      <GridItem mobile={16} tabletL={6} tabletLStart={9}>
                        <TextWrap>
                          {heading && <Heading2 as={`h2`}>{heading}</Heading2>}
                          <RichText content={popupCopy} />
                        </TextWrap>
                      </GridItem>
                    </Grid>
                  </Container>
                </>
              ) : (
                ``
              )}
            </TextModalInner>
            {/* </FocusTrap> */}
          </TextModal>
        </Outer>
      </Portal>
    </div>
  )
}

export default Modal
