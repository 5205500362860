import styled from 'styled-components'
import { font } from '../../styles/vars/font.style'
import { mq } from '../../styles/vars/media-queries.style'
import { ContainerMain } from '../Container/index.style'
import { setVh } from '../../styles/utils/conversion.style'

export const Outer = styled.div`
  ${({ modalOpen }) => {
    return modalOpen === false
      ? `opacity: 0;
      pointer-events:none;

      ${ContainerMain} {
        opacity: 0;
      }`
      : `opacity: 1;
      pointer-events:all;
      transition: opacity 0s 1s;

      ${ContainerMain} {
      opacity: 1;
      }
    `
  }}
`

export const TextModal = styled.div`
  ${({ modalOpen }) => {
    return modalOpen === false
      ? `opacity: 0;
      pointer-events:none;

      ${ContainerMain} {
        opacity: 0;
      }`
      : `opacity: 1;
      pointer-events:all;
      transition: opacity 0s 1s;

      ${ContainerMain} {
      opacity: 1;
      }
    `
  }}
  height: 100vh;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 1s ease 0.5s;
  z-index: 15;

  h2 {
    margin-bottom: 4rem;
  }

  p:not(:last-of-type) {
    margin-bottom: 4rem;
  }
`
export const TextModalInner = styled.div`
  ${setVh('height', 100, true)}
  opacity: 0;
  overflow: auto;
  padding-top: 5rem;
  position: relative;
  transition: opacity 1s ease 0.5s;
  width: 100%;

  ${mq.tabletL} {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 10rem;
    height: 100%;
  }

  ${mq.deskL} {
    justify-content: center;
  }

  ${({ modalOpen }) => {
    return modalOpen === false ? `opacity: 0;` : `opacity: 1;`
  }}
`

export const CloseButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  font-family: ${font.secondary.family};
  position: absolute;
  right: calc((100% / 16));
  top: 4rem;
  text-transform: uppercase;
  color: currentColor;
`

export const TextWrap = styled.div`
  padding-bottom: 5rem;

  ${mq.tabletL} {
    padding-bottom: 10rem;
  }

  ${mq.deskL} {
    padding-right: calc((100vw / 16) * 0.5);
  }

  h2 {
    ${mq.deskL} {
      max-width: 58rem;
    }
  }
`
