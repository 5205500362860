import React from 'react'
import { graphql } from 'gatsby'

// Components
import Footer from '../components/Footer'
import Seo from '../components/Seo'
import ScrollSection from '../components/ScrollSection'
import StandardContent from '../components/StandardContent'
import HalfAndHalfText from '../components/5050Text'
import InViewSection from '../components/InViewSection'
import DividingLine from '../components/DividingLine'

const StandardTemplatePage = ({ data }) => {
  const templateData = data.contentfulPageTemplateStandardContentPage
  const {
    supportedUseCasesTitle,
    supportedUseCasesCopy,
    supportedUseCasesButton,
    unsupportedUseCasesTitle,
    unsupportedUseCasesCopy,
    unsupportedUseCasesButton,
  } = data.contentfulPageGuidelines

  return (
    <>
      <Seo
        title={templateData.seoTitle ? templateData.seoTitle : `Copy`}
        description={templateData.description}
      />

      <StandardContent
        title={templateData.heroTitle}
        content={templateData.content}
      />

      <ScrollSection trackInView={false}>
        <InViewSection>
          <DividingLine />
        </InViewSection>

        <HalfAndHalfText
          title1={supportedUseCasesTitle}
          copy1={supportedUseCasesCopy}
          button1={supportedUseCasesButton}
          title2={unsupportedUseCasesTitle}
          copy2={unsupportedUseCasesCopy}
          button2={unsupportedUseCasesButton}
        />
      </ScrollSection>

      <ScrollSection>
        <Footer />
      </ScrollSection>
    </>
  )
}

export default StandardTemplatePage

export const pageQuery = graphql`
  query pageContentPagesBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulPageTemplateStandardContentPage(slug: { eq: $slug }) {
      ...contentQuery
    }
    contentfulPageGuidelines {
      supportedUseCasesTitle
      supportedUseCasesCopy {
        raw
      }
      supportedUseCasesButton {
        buttonText
        buttonLink
      }
      unsupportedUseCasesTitle
      unsupportedUseCasesCopy {
        raw
      }
      unsupportedUseCasesButton {
        buttonText
        buttonLink
      }
    }
  }
`
