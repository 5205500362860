import React from 'react'
import AnimateFadeIn from '../animation/AnimateFadeIn'

// Components
import DividingLine from '../DividingLine'
import Grid from '../Grid/index'
import GridItem from '../GridItem/index'
import RichText from '../RichText'
import ScrollSection from '../ScrollSection'
import { Heading1 } from '../TextStyles'
import BackArrow from '../WellbeingModules/BackArrow'

import { StandardContentHero, StandardContentMain } from './index.style'
import styled from 'styled-components'

const StandardContent = ({ title, content }) => {
  return (
    <>
      <ScrollSection>
        <>
          <StandardContentHero>
            <AnimateFadeIn>
              <FlexContainer>
                <BackArrow/>
                <Heading1>{title}</Heading1>
              </FlexContainer>
            </AnimateFadeIn>
          </StandardContentHero>
          <DividingLine />
        </>
      </ScrollSection>

      <ScrollSection>
        <StandardContentMain>
          <Grid>
            <GridItem
              mobile={16}
              mobileL={12}
              mobileLStart={3}
              tabletL={8}
              tabletLStart={5}
            >
              <AnimateFadeIn delay={1}>
                <RichText content={content} small />
              </AnimateFadeIn>
            </GridItem>
          </Grid>
        </StandardContentMain>
      </ScrollSection>
    </>
  )
}

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export default StandardContent
