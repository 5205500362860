import styled from 'styled-components'
import { colors } from '../../styles/vars/colors.style'
import { easings } from '../../styles/vars/easings.style'
import { mq } from '../../styles/vars/media-queries.style'
import { Heading2, Heading3 } from '../../styles/vars/textStyles.style'
import { FadeInWrapper } from '../animation/AnimateFadeIn/index.style'
import { Button } from '../Button/index.style'
import { GridItemMain } from '../GridItem/index.style'

export const ContentSection = styled.div`
  position: relative;
  padding-top: 10rem;
  padding-bottom: 10rem;

  ${mq.tabletL} {
    padding-top: 16.4rem;
    padding-bottom: 16.4rem;
  }

  ${FadeInWrapper} {
    ${mq.tabletP} {
      max-width: 80%;
    }
  }

  ${GridItemMain}:first-child & {
    :after {
      ${mq.tabletLMax} {
        content: '';
        position: absolute;
        left: -2.2rem;
        right: -2.2rem;
        bottom: 0;
        height: 0.1rem;
        background-color: ${colors.dark};
        opacity: 0;
        transition: opacity 1s ${easings.modal.default} 0.5s;
      }
    }
  }

  ${GridItemMain}:first-child [data-inview='true'] &:after {
    opacity: 0.3;
  }
`

export const TextSection = styled.div`
  position: relative;

  ${Heading3} {
    margin-bottom: 3rem;
  }

  ${Heading2} {
    display: inline-block;
    margin-bottom: 2rem;

    ${mq.desk} {
      margin-bottom: 4rem;
    }
  }

  ${Button} {
    margin-top: 7rem;
  }
`

export const TextSectionDivider = styled.div`
  ${mq.tabletL} {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 0.1rem;
    background-color: ${colors.dark};
    opacity: 0;
    transition: opacity 1s ${easings.modal.default} 0.5s;
  }

  [data-inview='true'] & {
    opacity: 0.3;
  }
`

export const OverlayToggle = styled.button`
  position: relative;
  top: -1.3em;
  display: inline-block;
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  background-color: transparent;
  border: none;

  ${mq.tabletL} {
    top: -1.7em;
    width: 4rem;
    height: 4rem;
  }
`
