import styled from 'styled-components'
import { mq } from '../../styles/vars/media-queries.style'

export const StandardContentHero = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 11.7rem;
  padding-bottom: 10rem;

  ${mq.tabletL} {
    padding-top: 22.1rem;
    padding-bottom: 18rem;
  }
`

export const StandardContentMain = styled.div`
  padding-bottom: 10rem;
  padding-top: 10rem;

  ${mq.tabletL} {
    padding-bottom: 14rem;
    padding-top: 16rem;
  }

  * + h3 {
    margin-top: 2.6em;
  }
`
