import React from 'react'

const IIcon = ({
  width = 40,
  height = 40,
  stroke = '#353535',
  responsive = false,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      aria-hidden="true"
      focusable="false"
      {...svgAttributes}
    >
      <path
        d="M19.704 11C18.768 11 18 11.792 18 12.704C18 13.664 18.768 14.432 19.704 14.432C20.64 14.432 21.432 13.664 21.432 12.704C21.432 11.792 20.64 11 19.704 11ZM18.36 16.448V28.184H21.024V16.448H18.36Z"
        fill={stroke}
      />
      <circle cx="20" cy="20" r="19.5" stroke={stroke} fill={`none`} />
    </svg>
  )
}

export default IIcon
