import React, { useContext, useState, useEffect } from 'react'

// Components
import { StoreDispatch } from '../../Store'
import Button from '../Button/index'
import Grid from '../Grid/index'
import GridItem from '../GridItem/index'

import { Heading2 } from '../../styles/vars/textStyles.style'
import {
  TextSection,
  ContentSection,
  TextSectionDivider,
  OverlayToggle,
} from './index.style'
import RichText from '../RichText'
import AnimateFadeIn from '../animation/AnimateFadeIn'
import InViewSection from '../InViewSection'
import IIcon from '../svgs/I'
import TextModal from '../TextModal'

const Text = ({ title1, copy1, button1, overlay1, title2, copy2, button2 }) => {
  const dispatch = useContext(StoreDispatch)
  const [modalOpen, setModalOpen] = useState(false)
  const sectionDelay = 0.5

  useEffect(() => {
    if (modalOpen === true) {
      if (window.innerWidth <= 1024) {
        document.querySelector('body').classList.add('disable-scroll')
      } else if (window?.scroll?.stop) {
        window.scroll.stop()
      }
      dispatch({ type: 'SHOW_MODAL' })
      dispatch({ type: 'CIRCLE_GROW' })
    }

    if (modalOpen === false) {
      dispatch({ type: 'CIRCLE_SHRINK' })
      dispatch({ type: 'HIDE_MODAL' })
      if (window.innerWidth <= 1024) {
        if (
          document.querySelector('body').classList.contains('disable-scroll')
        ) {
          document.querySelector('body').classList.remove('disable-scroll')
        }
      } else if (window?.scroll?.update) {
        window.scroll.update()
        window.scroll.start()
      }
    }
  }, [modalOpen, dispatch])

  useEffect(() => {
    const checkKey = e => {
      if (modalOpen) {
        if (e.key === 'Escape' || e.code === 'Escape') {
          setModalOpen(false)
        }
      }
    }

    window.addEventListener('keyup', checkKey)

    return () => {
      window.removeEventListener('keyup', checkKey)
    }
  }, [modalOpen, setModalOpen])

  function setOpen() {
    setTimeout(() => {
      return setModalOpen(!modalOpen)
    }, 50)
  }

  return (
    <>
      <TextSection>
        <InViewSection>
          <TextSectionDivider />
        </InViewSection>

        <Grid>
          <GridItem
            mobile={16}
            tabletP={16}
            tabletL={5}
            tabletLStart={3}
            deskL={4}
            deskLStart={4}
          >
            <InViewSection>
              <ContentSection>
                <AnimateFadeIn delay={sectionDelay}>
                  <Heading2 as={`h2`}>{title1}</Heading2>
                  {overlay1 && (
                    <OverlayToggle aria-label="Open overlay" onClick={setOpen}>
                      <IIcon responsive />
                    </OverlayToggle>
                  )}

                  <RichText content={copy1} />

                  {button1 && (
                    <Button
                      to={button1.buttonLink}
                      text={button1.buttonText}
                      hasborder
                      theme={2}
                    />
                  )}
                </AnimateFadeIn>
              </ContentSection>
            </InViewSection>
          </GridItem>

          <GridItem
            mobile={16}
            tabletP={16}
            tabletL={5}
            tabletLStart={10}
            deskL={4}
            deskLStart={10}
          >
            <ContentSection>
              <InViewSection>
                <AnimateFadeIn delay={sectionDelay}>
                  <Heading2 as={`h2`}>{title2}</Heading2>
                  <RichText content={copy2} />
                  {button2 && (
                    <Button
                      to={button2.buttonLink}
                      text={button2.buttonText}
                      hasborder
                      theme={2}
                    />
                  )}
                </AnimateFadeIn>
              </InViewSection>
            </ContentSection>
          </GridItem>
        </Grid>
      </TextSection>

      <TextModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        tabIndex={modalOpen ? -1 : 1}
        aria-live="polite"
        heading={title1}
        popupCopy={overlay1}
      />
    </>
  )
}

export default Text
